<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-12 text-right">
        <button class="btn btn-primary" @click="modal_usuario=true">Agregar usuario</button>
      </div>
    </div>
    <div class="lista_usuarios">
      <table class="table-usuarios">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Estatus</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(usuario,index) in alianza.usuarios">
            <td>{{ usuario.nombre }}</td>
            <td>{{ usuario.estatus }}</td>
            <td>
              <button class="btn btn-outline-primary mr-2" @click="editar_usuario(usuario)">
                <i class="fa-solid fa-pencil"></i>
              </button>
              <button class="btn btn-outline-primary" @click="eliminar_usuario(index)">
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Editar v-if="modal_usuario" :usuario="usuario_tmp" @update="agregar_usuario" @close="modal_usuario=false"/>
  </div>
</template>

<script>
import Editar from './Usuario/Editar'

export default {
  components: {
    Editar
  },
  props: {
    alianza: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modal_usuario: false,
      alianza_tmp: {
        id: null,
        nombre: null,
        descripcion: null,
        usuarios: [],
        documentos: []
      },
      usuario_tmp: {
        account_id: null,
        nombre: null,
        email: null,
      },
      usuario_index: null
    }
  },
  mounted() {
    this.alianza_tmp = this.alianza;
  },
  methods: {
    agregar_usuario(usuario) {
      if (!usuario.id) {
        if (this.usuario_index === null)
          this.alianza_tmp.usuarios.push(usuario);
        else {
          for(let i=0; i<this.alianza_tmp.usuarios.length; i++) {
            if (i === this.usuario_index)
              this.alianza_tmp.usuarios[i] = usuario;
          }
        }
      }else
        for(let i=0; i<this.alianza_tmp.usuarios.length; i++) {
          if (this.alianza_tmp.usuarios[i].id === usuario.id)
            this.alianza_tmp.usuarios[i] = usuario;
        }
      this.modal_usuario = false;
    },
    editar_usuario(usuario) {
      this.usuario_tmp = usuario;
      this.modal_usuario = true;
    },
    eliminar_usuario(index) {
      this.alianza_tmp.usuarios.splice(index,1);
    }
  },
  watch: {
    alianza: {
      handler(val) {
        this.alianza_tmp = val;
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.lista_usuarios {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  .table-usuarios {
    width: 100%;

    thead {
      tr {
        th {
          background-color: grey;
          padding: 7px 12px;
        }

        th:nth-child(2) {
          width: 200px;
        }

        th:nth-child(3) {
          width: 120px;
          text-align: center;
        }
      }
    }

    tbody {
      tr:nth-child(2n+0) {
        background-color: hsl(0, 0%, 90%);
      }
    }
  }
}
</style>